import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { propertyData } from "../components/properties/propertyData";
import { Details } from "../components/propertyPage/details";
import { Footer } from "../components/propertyPage/footer";
import { Images } from "../components/propertyPage/images";
import { Map } from "../components/propertyPage/map";
import { MatterPort } from "../components/propertyPage/matterPort";

import { Title } from "../components/propertyPage/title";
import { Video } from "../components/propertyPage/video";
import { DynamicMetaTags } from "../utils/dynamicMetaTags";

export const PropertyPage = () => {
  let { propertyId } = useParams();
  const [propertyItem, setPropertyItem] = useState({
    id: "0",
    thumbnailUrl: "",
    title: "",
    description: "",
    ogDescription: "",
    type: "",
    bathrooms: "",
    bedrooms: "",
    floor: "",
    area: "",
    askingPrice: "",
    parkingSpaces: "",
    elevator: true,
    generator: true,
    location: "",
    propertyPdf: "",
    googleMapPin: "",
    commericalTerms: [{ id: 0, cat: "", text: "" }],
    lettingDetails: [{ id: 0, cat: "", text: "" }],
    keyFeatures: [{ id: 0, cat: "", text: "" }],
    documents: [
      {
        id: 1,
        docUrl: "",
        title: "",
      },
    ],
    video: [{ id: 0, title: "", url: "" }],
    images: [
      {
        alt: "",
        url: "",
      },
    ],
  });

  useEffect(() => {
    const propertyItem = propertyData.find((data) => data.url === propertyId);
    setPropertyItem(propertyItem);
  }, [propertyId]);

  return (
    <div>
      <DynamicMetaTags
        ogTitle={propertyItem.title}
        ogUrl={`pages/id/${propertyItem.url}`}
        ogDescription={propertyItem.description}
        ogImage={propertyItem.thumbnailUrl}
      />

      <Title propertyItem={propertyItem} propertyId={propertyId} />
      <Images propertyItem={propertyItem} propertyId={propertyId} />
      <Details propertyItem={propertyItem} propertyId={propertyId} />
      {propertyItem.matterport && <MatterPort propertyItem={propertyItem} />}
      {propertyItem.video && <Video propertyItem={propertyItem} />}

      <Map propertyItem={propertyItem} />
      <Footer />
    </div>
  );
};
