import { Col, Divider, Row, Space } from "antd";
import { ContactUs } from "../landingPage/contactUs";

export const Footer = () => {
  return (
    <div>
      <Row gutter={[18, 18]} style={{ margin: "20px" }}>
        <Divider></Divider>
        <Col xs={24} sm={24} md={9}>
          <p style={{ fontWeight: "900" }}>Peoples Media Network</p>
          <p style={{ fontSize: "14px" }}>
            Peoples Media Network is operates the Buddhist Radio and Television
            Network in Sri Lanka. The company provides owns and managers a
            network of transmission site across the country.{" "}
          </p>
          <Space>
            <a
              style={{
                fontSize: "14px",
                // textDecoration: "none",
                color: "salmon",
              }}
              href={"https://eastwest.lk"}
            >
              {"East West Properties PLC"}
              {/* <img
                style={{ height: "15px" }}
                src={
                  "https://jdevs-app.s3.eu-west-2.amazonaws.com/04-slack-artwork/slack-2.svg"
                }
                alt={"slack logo"}
              /> */}
            </a>
          </Space>
          <p>
            East West Properties PLC is a publically listed on the Colombo Stock
            Exchange.
          </p>
        </Col>{" "}
        <Col xs={0} sm={0} md={5}></Col>
        <Col xs={24} sm={24} md={10}>
          <ContactUs />
        </Col>
        {/* <Col xs={12} sm={12} md={5}>
          <p style={{ fontWeight: "700" }}>Company</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={"https://eastwest.lk"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={12} md={5}>
          <p style={{ fontWeight: "700" }}>Community</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={
                  "https://www.linkedin.com/company/east-west-properties-plc/"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
            </li>
          </ul>
        </Col> */}
        <Divider orientation="left">Contacts</Divider>
      </Row>

      <Row gutter={[18, 18]} style={{ margin: "10px" }}>
        <Col xs={24} sm={24} md={8}>
          <p style={{ fontWeight: "700" }}>Company</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={"https://eastwest.lk"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </li>
          </ul>
          <p style={{ fontWeight: "700" }}>
            SALES, SERVICE AND INFERTUCTURE PROJECTS
          </p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <p
                style={{
                  fontSize: "14px",
                  color: "salmon",
                }}
              >
                Daya Buddhadasa - MANAGER
              </p>
            </li>
            <li>
              Mobile:
              <a href="https://wa.me/94714917206">+94 71 4917206</a>
            </li>
            <li>
              Hotline:
              <a href="tel:+94712244442">+94 71 2244442 </a>
            </li>
            <li>
              Office:
              <a href="tel:+94114845443">+94 11 4845443</a>
            </li>
            <li>
              Fax:
              <a href="tel:+94112919239">+94 11 2919239</a>
            </li>
            <li>
              <a href="mailto:daya@eastwest.lk">daya@eastwest.lk</a>
            </li>
          </ul>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <p style={{ fontWeight: "700" }}>
            TECHNICAL SUPPORT &amp; SERVICE INQUIRY
          </p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <p
                style={{
                  fontSize: "14px",
                  color: "salmon",
                }}
              >
                Clarence Manual - Senior Engineer
              </p>
            </li>
            <li>
              Mobile
              <a href="https://wa.me/94771556340">+94 77 1556340</a>
            </li>
            <li>
              Office:
              <a href="tel:+94114845439">+94 11 4845439</a>
            </li>
            <li>
              Fax:
              <a href="tel:+94112919239">+94 11 2919239</a>
            </li>
            <li>
              <a href="mailto:clarence@ew.lk">clarence@ew.lk</a>
            </li>
          </ul>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <p style={{ fontWeight: "700" }}>R&amp;D LAB</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <p
                style={{
                  fontSize: "14px",
                  color: "salmon",
                }}
              >
                MANJULA CHATHURANGA
              </p>
            </li>
            <li>
              Mobile
              <a href="https://wa.me/94771556340">+94 77 1556340</a>
            </li>
            <li>
              Office:
              <a href="tel:+94114845439">+94 11 4845439</a>
            </li>
            <li>
              Fax:
              <a href="tel:+94112919239">+94 11 2919239</a>
            </li>
            <li>
              <a href="mailto:chathuranga@ew.lk">chathuranga@ew.lk</a>
            </li>
          </ul>
          <p style={{ fontWeight: "700" }}>PMN Center</p>
          <p>
            Peoples Media Network (Pvt) Ltd, No: 81/11 New Nuge Road Peliyagoda.
          </p>
        </Col>
      </Row>
      <Divider />
      <Row justify="center">
        <Col span={24}>
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: "12px" }}>
              {"built by "}
              <a
                href="https://techforgood.lk"
                target={"_blank"}
                rel={"noreferrer"}
                style={{ textDecoration: "none" }}
                color="salmon"
              >
                {"techForGood.lk "}
              </a>
              &copy; {"2023"}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};
