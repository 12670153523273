export const MatterPort = ({ propertyItem }) => {
  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        margin: "15px",
      }}
    >
      <iframe
        width="853"
        height="480"
        title={`matterport - ${propertyItem.title}`}
        src={propertyItem.matterport}
        frameborder="0"
        allowfullscreen
        allow="xr-spatial-tracking"
      ></iframe>
    </div>
  );
};
