import { Col, Row } from "antd";

export const Map = ({ propertyItem }) => {
  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Row
        gutter={[4, 4]}
        style={{ maxWidth: "1200px", margin: "15px", width: "100%" }}
      >
        <Col span={24}>
          <iframe
            id="googleMap"
            src={propertyItem.googleMapPin}
            width="100%"
            height="450"
            title={`map - ${propertyItem.title}`}
            style={{ border: "0", marginBottom: "15px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Col>
      </Row>
    </div>
  );
};
