import { Divider } from "antd";

import { Hero } from "../components/landingPage/hero";
import { AboutUs } from "../components/landingPage/aboutUs";
import { Examples } from "../components/landingPage/examples";
import { Pricing } from "../components/landingPage/pricing";

import { Footer } from "../components/navigation/footer";
import { DynamicMetaTags } from "../utils/dynamicMetaTags";

export const LandingPage = ({ helloWorld }) => {
  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <DynamicMetaTags ogTitle={`People Media Network`} ogImage={""} />
      <div style={{ maxWidth: "1200px" }}>
        <Hero />
        <Divider />
        <AboutUs />
        <Divider />
        <Examples />
        <Divider />
        <Pricing />
        <Footer />
      </div>
    </div>
  );
};
