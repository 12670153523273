import { Image, List } from "antd";

export const Images = ({ propertyItem }) => {
  // image guide
  // ogImages - 1200px by 630 px
  // gallery images 1024 by 768 px

  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Image.PreviewGroup>
        <List
          pagination={{
            pageSize: 6,
            align: "center",
            defaultCurrent: 1,
            hideOnSinglePage: true,
          }}
          style={{ maxWidth: "1200px" }}
          grid={{
            gutter: 0,
            xs: 2,
            sm: 3,
            md: 6,
            lg: 6,
            xl: 6,
            xxl: 6,
          }}
          dataSource={propertyItem.images}
          renderItem={(data) => (
            <List.Item style={{ padding: "5px" }}>
              <Image
                style={{ maxWidth: "250" }}
                src={data.url}
                alt={data.alt}
              />
            </List.Item>
          )}
        />
      </Image.PreviewGroup>
    </div>
  );
};
