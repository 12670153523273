import { Card, Col, Row } from "antd";

export const AboutUs = () => {
  return (
    <div
      style={{
        background: "rgb(225, 225, 219)",
        textAlign: "center",
        backgroundImage: `url(https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1636840141292x334303741495010750%2F25%2520SRL-anuradhapura-bodhi-02.jpg?w=1536&h=614&auto=compress&dpr=1&fit=crop&q=100)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        maxWidth: "1200px",
        width: "100%",
        minHeight: "420px",
        padding: "25px",
      }}
      id="projects"
    >
      <div
        style={{
          color: "white",
          fontWeight: "700",
          fontSize: "1.5rem",
          textAlign: "left",
        }}
      >
        <span style={{ background: "black", padding: "5px" }}>
          BUDDHIST RADIO & TELEVISION NETWORK
        </span>
      </div>
      <br></br>
      <Row gutter={[18, 16]}>
        <Col xs={24} sm={24} md={8} style={{ textAlign: "center" }}>
          <Card title="Step 1" bordered={true}>
            <Row>
              <Col span={24}>
                <img
                  alt="send"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADJklEQVR4nO2cPWsUQQCGHw9iE3PaWBmtRPCr1VZLURIEbf0H1nZq40ejEaxsNTa2giABLa3VfJgrVRALA5oYAye3MjAHYW733Lubu52deR+YKsckvM/tvHezmwEhhBBCCCGEEEIIIYQQQgjhchJYAJaBLSDTYHcGWzabh8CJcb599gKPgb8SQNk3ocnqETA1DhlLEsGwq8Fr31LMlaGliZEyMFeKt85wl6kVYA6Y8fVLImIGmAdWnczawHEfv2AhR0bTx8SRsx9Yc7J74GPiFWdSY1+U47KT3Uc88MuZVMtUeZpOdibLkXHLSVScn4SMhoQEhoQEhoQEhoQERjRCloDT1J9ohGR2u+EJcJD6EpWQzI4N4Ibdda4bUQrJ7FgHrlIvohaS2VGnfklCSFajfolWyHaBmB/A9XHcJvVEtEJmgadAp2b9Eq2QLmeAdzXql+iFGPYA14BvNeiXJIR0mQZuA38KxITw/SUpIV2O2H7JAuyXJIV0OQ+8D6xfkhZiaNh++R5IvyQvpMsB4D6wU3G/SIjDMeBFhf0iITlCXv5HyCXGh4RYtGR5JlOpp/Gx9xSTJckl62iJ4h5nT/QjKSHT2joJQ0hDm4vhXCFnS2y/T7onklyyZkvcoKqqJ5IUsl0gQrdwKxKSVbxJOCzRXiFZwD2RrJD1QHsiOSEbAdyKHZaohLRr0hNJCHnl6x/tKyYaIbEgIYEhIYEhIYEhIYEhIbELcTf1dPjM8IfP/MYDy86kOp5p+OOZPuCBe86kq/ZwLtEfk9EnJ7s7eOCw3cLYPfGata+T5Xpp2mxcGW17k600F4GvJe5DaOA1gy/AhTwh5gcKm0oy+FzmKD8NJpbBzzwh7pqnwcQyMJ3cw6Lzoh1b6MIvh3K+25mnaXq4kmPOSBJ+eZ6Ts8m+B3MSQivnxTc9/0Epcysn31a/UyjmCx5EewOcs8/SisHYZ5/Kf5uTa8ce2T7QN3MNxpbB3TJGG/aFRY9sajByBh2bscm6NHMFnaLBSBm0yixTRUzZTwCL9rPypoQwqIBNuyH7zGYZ6jFSQgghhBBCCCGEEEIIIYQQTIJ/L5EJsw2iOPwAAAAASUVORK5CYII="
                ></img>
              </Col>
            </Row>
            <ul style={{ listStyle: "none", padding: "0px" }}>
              <li>Send us your property details</li>
              <li>Images & Videos</li>
            </ul>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} style={{ textAlign: "center" }}>
          <Card title="Listen" bordered={true}>
            <Row>
              <Col span={24}>
                <img
                  alt="done"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADjklEQVR4nO2bu2sUURSHPx/4SiH4IFrYWKhE0ogogmIhiiFoJSIqxkIIWKhE8A/Q2GmRQsVCjIIIYkS0TG0nviCgAUUTjYVCFB9RjK4Me1fHde7sTnZn5s7M74NTZJPJnLlf5p4zd25ACCGEEEIIIYQQQgghhMgTB4A3wAtga9rJFJm5wEWg5IuPwNK0Eysiq4AnVTIq0Z92ckWcoj5bZHjxC9icdpJFoMX89dsk+L9+BMxMO+GiTlFXgW0BUo6knbRrrAOuA2MBg9WM8KatLt/5rlR9/wPQmuL1O8M04CTwMwYJlRgC2qrO22ok+H/Ok0TR5/mBGEVUpijvPEEcVYH/yzLgQYwiXpsOKwyvkD9WgYf1wNuAQfQ+255wx7Ox6AV+LzARIOOhuWuSZo6pMf5cRihI8e61dFC3Qub5OFltaY9fUdDi/Qs4DUxPIafDwNeAnD4BnRSweE8A+1LIZyFw29II3AdWUMDi/Q7YlEI+a8yye9Cd2gfMIkd0mlaz2e3rS+CQqUGN0A18q+N8o0AHOWA05ge8gSm2w/NCFhltkYsuq5RAdEfMablZyZ3KuTJPEkLuRMhnC/C+gXNlnmZfUJd5xer/nTfqPNZ70v4RkNNTYGVC+adOsy6oJWTO313j2NnAJcuxd4H5CeTvDM24oLaAZYxKXK5x7BLgnqWl7a3j4VNCqjhoeff9BThWY0DXWro876l7F/UhIYYZwDnLXTFk1ptseA9yxy1LIM+BdupHQgynLDL6ayw47gSGLccOAguIhoRQXuf6HjBFedOXjXYz4LZ29ay566IiIZTbU/9AjIVMUYuAC8CkRcR4g4uVEgKcrxqIHkud6DEDHiRi0oha3IAMCTH0VQ2u15762QE8C5meBiMW7jB0h1CuFf6B8FZjT5gHwLA6MWyKejOREMpPzlHWm8bN9BXHewsJMeypY6dis+pEGBLiY3/ALsI46kQYEhLQ1npt8DXgJnAG2EBySIhjSIhjSIhj5F5I1iPzpD2AJQlJdhtQKcHIxTagjpxIGTH/EiGEEEIUbne6gkhj8Gd3fR46oFKe2uq0k1DwzxhoQHBrDP7/IGOU8pZ/7i4oY0iIY0iIY0iIY0iIY0iIY0iIY0iIY0iIY0iIY0iIY0iIY0iIY0iIY0iIY0iIY0hI1oQocOsVroJ0hWgbEG5tA8rL7vRSxkO764UQQgghhBBCCCGEEIK88BuVFHWOtQIcHgAAAABJRU5ErkJggg=="
                />{" "}
              </Col>
            </Row>
            <ul style={{ listStyle: "none", padding: "0px" }}>
              <li>FM101.3 & FM101.5</li>
            </ul>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} style={{ textAlign: "center" }}>
          <Card title="Watch" bordered={true}>
            <Row>
              <Col span={24}>
                <img
                  alt="example"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE4ElEQVR4nO2cXYxdUxTHf7cGkY6vSUg0Qo1vIbRKBYmSeKaVkmknEW8iHgg1aNBWJF4aH2+8NUQj4jO8SEcJbbWCREJ9E6kgUxnVCm0zrmzZIyfLPnfO2Wffzp3Z/1+yX2ZmrbPv+t/ZH2vvs0AIIYQQQgghhBBCCCGEEEIIIWJoARcD64DNwOfAPqCdWdsH7PQxWAssnA4hbgS+6IFgtHu0udgs97HqKqcD23vgA7dnSNsGDHZLjCuBX3rgQ7ZnWPsVuCa1GFcDBwIP2w88DwwBZwNzyY+5/rMP+VjsL4nTkpTD1O7AQ17s5r/jDMbF66VAvMaA05o6bwXmjAlgVZq+z2ru8bEqxm5r04n+poDSd6fr86xnJBC/G2KdtQJLWzdMiXq8bGLo9ixRLApMTG58FPUYDEz0C4hgnXHiVhAijhdMLNfEONlsnLhlnYhjpYnlaIyTL42TMyM7I/h3n1KMpcv71WavcdKvyEbTb2LpYlsbu1wTzWgcTwmSsSDnAQ8DbwM/A3/53JlLN7wPPOaTm91iDvBm4bO6BU2WgpwIbAT+rpg5fQ+4oAv9uMs8J0tB5gPfRZ7QXZuwHwsDG7fsBDm85KDrd+BZ4H7gQeCpEtHGgbMSpc53BvxnJ8jqgP/XgONLxvcVwJ4UmyvD0yX/hVkJcqSfuIu+NwFHTGF3mZ/si3aXN+jHsg7DYlaCXNcgafmIsX08sg8n+6PVST8uq/1BroKsb5C0nGcOfXZEPH+OydO55fUl5mdZCbLJ+HVXiurwVcHWXbqoy33m+W4+I2dBPjN+3fK3DtsLtn/WtL3UXNZ4Fzgsd0G+L/h0G8K+mvY/Fuydr6ocDXxdsP3NfBmyFeRWf9bs2p01bc81fXqjhu0GYztsfp+tIE14wvTp9op2Q8bOpWssEiTijP+g2a0fV8Fuvh+eJu1+KLGTIDU4BdhlvuVuyJuKPp+QnLSZ6HCDUIJU5HyzEHDtncLqqBNrjN2jHf5WglRghU84FoP6CTBQwfYKM8R9OEV6RoJ0YMBnfUPX+0+oIMaxJkv8h1+hdUKClHA98FNADJeZPYpqbDS2t1WwkSCBjduGgBBjPiFZlZuN/esVLzhLkAKLzS667Zu71n8S9bBzzvrCRrRT+6Zg86l/Ja3YstkY3hF4GWgXsDTSX7tLLQtBbCq+DTzjJ+VYJEgka03w9gTySxKkwzct9Uqq6PvbRJcWmjCea3JxwJypu0OmU5l+xnMV5AHjN3byTk2WgvSZd93d6WGvMJ6jIIuNz48q7hFGStotpCNLQVYlXpZ+TDqyFORJCUJPCfKcBKGnBMmZdi8OWTnTbhpPmzXVS5/xHBNIE9XGltWY7tTGTOacFOU13jJO3Pm2iGM4xbstNhur0hrpSms8FPvuXdGJO0Q6o0GncmUw8A7jhTGOWr4EhD1SFfVi+GqK+WOS5YHlmqrJVefeQPyWNlV4m3E44cvXic5xGwmU+NuSopbvoL+KY5V2ldI0p/wfVzXplUC8xiJeTiplSUmZ2AN+BbHSr7Vz3Dz2+1uRwz4WZeV0r0r9YBVSJuqIYHc3CikXh6+tDc8wcmpbUg5TZbR8qdNQSQo1/lvaLjsUxfgtC/w7GKO+E7YKXQ5tr//so34HftGhFkEIIYQQQgghhBBCCCGEEEIIwSzhH1SxIZFWiXJWAAAAAElFTkSuQmCC"
                />{" "}
              </Col>
            </Row>
            <ul style={{ listStyle: "none", padding: "0px" }}>
              <li>UHF 53 & UHF57</li>
            </ul>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
