import { Button } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";

export const Availability = ({ propertyTitle, contactWhatsApp }) => {
  return (
    <Button
      style={{ backgroundColor: "#128c7e", color: "white" }}
      target={"_blank"}
      rel="noferrer"
      href={`https://api.whatsapp.com/send/?phone=${contactWhatsApp}&text=I am interest in your ${propertyTitle} property`}
    >
      <WhatsAppOutlined style={{ color: "white", verticalAlign: "baseline" }} />
      {"Check Availability"}
    </Button>
  );
};
