import { Col, Row } from "antd";

export const Pricing = () => {
  return (
    <div
      style={{
        background: "rgb(225, 225, 219)",
        textAlign: "center",
        backgroundImage: `url(https://www.ors.at/fileadmin/_processed_/b/1/csm_ORS-LP-Sendernetz-Sender-Semmering-Schuetz-1920x600_708a08473a.jpg)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        maxWidth: "1200px",
        width: "100%",
        minHeight: "420px",
        padding: "25px",
      }}
      id="projects"
    >
      <div
        style={{
          color: "white",
          fontWeight: "700",
          fontSize: "1.5rem",
          textAlign: "left",
        }}
      >
        <span style={{ background: "black", padding: "5px" }}>
          OUR NETWORK OF TOWERS....
        </span>
      </div>
      <br></br>
      <Row
        gutter={[18, 16]}
        style={{
          padding: "15px",
          justifyContent: "center",
          opacity: "1",
        }}
      >
        <Col xs={24} sm={24} md={12} style={{ textAlign: "center" }}>
          <iframe
            src="https://www.google.com/maps/d/u/0/embed?mid=1klTHCUW2CAjzqlp8AyzJmaW4r5H1cDo&ehbc=2E312F"
            width="100%"
            height="580"
            title="Tower `Locations"
          ></iframe>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          style={{ textAlign: "center", height: "100%" }}
        >
          <img
            // width={"100%"}
            alt="coverage map"
            height={"580"}
            src="https://upload.wikimedia.org/wikipedia/commons/b/b8/Coverage_map_2015.jpg"
          />
        </Col>
      </Row>
    </div>
  );
};
