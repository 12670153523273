import { Card, Col, Row } from "antd";

export const Examples = () => {
  const exampleData = [
    {
      title: "ELNOS",
      description: "Transmitter supplier",
      url: "https://www.elenosgroup.com/",
      alt: "marine house view from the road",
      src: "https://i0.wp.com/www.elenos.com/wp-content/uploads/2018/07/4U-elenos-green-min.png?fit=720%2C286&ssl=1",
      type: "Office",
      size: "3200+ sqft",
      location: "Kollupitya",
    },
    {
      title: "Sonifex",
      url: "https://sonifex.lk",
      description: "Audio Products for AV, Installed Sound, Broadcast Radio",
      alt: "view of the living room",
      src: "https://d16lwq5o0fvd7.cloudfront.net/images/catalogue/products/g428asa/g428asa.jpg",
      type: "Apartment",
      size: "1 bedroom",
      location: "Crescat",
    },
    {
      title: "Jampro",
      url: "https://jampro.com/",
      alt: "front the warehouse",
      description: "Antenna Broadcast Systems",
      src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpayARAi-UgUohA1dNsvlzZ4Yr8aDj3kARCQ&usqp=CAU",
      type: "Warehouse",
      size: "10,000sqft",
      location: "Peliyagoda",
    },
    {
      title: "Warehouse - Peliyagoda",
      url: "/id/ew-warehouse-1",
      alt: "front the warehouse",
      src: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/examples/ogImage-pages-ew-warehouse-1.jpg",
      type: "Warehouse",
      size: "10,000sqft",
      location: "Peliyagoda",
    },
  ];

  const listExamples = exampleData.map((data) => {
    return (
      <Col xs={24} sm={24} md={6} style={{ textAlign: "center" }}>
        <a target="_blank" rel="noreferrer" href={data.url}>
          <Card
            title={data.title}
            style={{ padding: "15px" }}
            hoverable
            cover={<img alt={data.alt} src={data.src} />}
            // actions={[data.type, data.size, data.location]}
          >
            {data.description}
          </Card>
        </a>
      </Col>
    );
  });
  return (
    <div
      style={{
        background: "rgb(225, 225, 219)",
        textAlign: "center",
        backgroundImage: `url(https://img.theculturetrip.com/wp-content/uploads/2017/10/6485862885_4cda9d26c3_b.jpg)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        maxWidth: "1200px",
        width: "100%",
        minHeight: "420px",
        padding: "25px",
      }}
      id="projects"
    >
      <div
        style={{
          color: "white",
          fontWeight: "700",
          fontSize: "1.5rem",
          textAlign: "left",
        }}
      >
        <span style={{ background: "black", padding: "5px" }}>
          Presenting Leading Brands
        </span>
      </div>
      <br></br>
      <Row id="examples" gutter={[18, 16]}>
        {listExamples}
      </Row>
    </div>
  );
};
